import { useQuery } from '@apollo/client';
import type { Component } from '@wirechunk/lib/mixer/types/components.ts';
import type { FunctionComponent } from 'react';
import { useErrorCollector } from '../../../contexts/error-collector-context.tsx';
import { useSiteContext } from '../../../contexts/SiteContext/SiteContext.tsx';
import { ParseAndRenderQuillDelta } from '../../RenderQuillDelta/parse-and-render-quill-delta.tsx';
import { Spinner } from '../../spinner/spinner.tsx';
import { DefaultPrivacyPolicy } from '../../VisualBuilder/components/DefaultPrivacyPolicy.tsx';
import { PublicSitePrivacyPolicyDocument } from './queries.generated.ts';

export const SitePrivacyPolicy: FunctionComponent<Component> = () => {
  const { onError } = useErrorCollector();
  const { id, name } = useSiteContext();
  const { data, error, loading } = useQuery(PublicSitePrivacyPolicyDocument, {
    onError,
    variables: { siteId: id },
  });

  if (loading) {
    return <Spinner py="3" />;
  }

  if (data?.publicSite.useCustomPrivacyPolicy) {
    return data.publicSite.privacyPolicy ? (
      <ParseAndRenderQuillDelta delta={data.publicSite.privacyPolicy.delta} />
    ) : null;
  }

  if (error) {
    return null;
  }

  return <DefaultPrivacyPolicy orgLegalName={name} />;
};
